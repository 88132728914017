// utility/validation.js

export const isValidDate = (date) => {
  const datePattern = /^\d{2}\/\d{2}\/\d{2}$/;
  return datePattern.test(date);
};

export const isValidName = (name) => {
  const forbiddenCharacters = /^[A-Za-z\s]+$/;
  return forbiddenCharacters.test(name);
};

export const isValidPhoneNumber = (phoneNumber) => {
  // Regular expression to match exactly 14 digits
  const fourteenDigitPhoneNumberRegex = /^\d{14}$/;

  return fourteenDigitPhoneNumberRegex.test(phoneNumber);
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[A-Za-z\d\W]{6,}$/;

  return passwordRegex.test(password);
};

export const isValidExpiryDate = (date) => {
  const regex = /^(0[1-9]|1[0-2])\/\d{2}$/; // MM/YY format
  return regex.test(date);
};

export const isValidUSZipCode = (zip) => {
  const zipPattern = /^\d{5}(-\d{4})?$/;

  return zipPattern.test(zip);
};

export const isValidZip = (zipCode) => {
  const zipCodePattern = /^\d{5}(?:-\d{4})?$/;
  return zipCodePattern.test(zipCode);
};

export const isValidState = (state) => {
  const statePattern = /^[A-Z]{2}$/;
  return statePattern.test(state);
};

// VALIDATION OF TIME

export const validateTime = (startTime, endTime) => {
  const startTimeMs = Date.parse(`01/01/2000 ${startTime}`);
  const endTimeMs = Date.parse(`01/01/2000 ${endTime}`);

  if (isNaN(startTimeMs) || isNaN(endTimeMs)) {
    return "Invalid time format";
  }

  const startTimeParts = startTime.split(/:/);
  const endTimeParts = endTime.split(/:/);

  const startTimeHour = parseInt(startTimeParts[0]);
  const endTimeHour = parseInt(endTimeParts[0]);

  if (startTime.includes("PM") && startTimeHour !== 12) {
    startTimeParts[0] = startTimeHour + 12;
  } else if (startTime.includes("AM") && startTimeHour === 12) {
    startTimeParts[0] = 0;
  }

  if (endTime.includes("PM") && endTimeHour !== 12) {
    endTimeParts[0] = endTimeHour + 12;
  } else if (endTime.includes("AM") && endTimeHour === 12) {
    endTimeParts[0] = 0;
  }

  const adjustedStartTime = `${startTimeParts[0]}:${startTimeParts[1]}`;
  const adjustedEndTime = `${endTimeParts[0]}:${endTimeParts[1]}`;

  const adjustedStartTimeMs = Date.parse(`01/01/2000 ${adjustedStartTime}`);
  const adjustedEndTimeMs = Date.parse(`01/01/2000 ${adjustedEndTime}`);

  if (adjustedStartTimeMs >= adjustedEndTimeMs) {
    return "Start time should be before end time";
  }

  return "Time is valid";
};

// Convert in MM/DD/YYYY

export const dateFormatter = (dateString) => {
  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  return `${month}/${day}/${year}`;
};


export const OptionsClassDetailsPage = {
  0: {
    items: 1,
  },
  768: {
    items: 3,
  },
  1024: {
    items: 4,
  },
};
import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as LOGO } from "../../parent-assets/images/gymtime_logo_2024.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Home } from "../../parent-pages";
import { toast } from "react-toastify";
import SuccesModal from "./SuccesModal";
import { useDispatch } from "react-redux";
import { setSuccessModalContent } from "../../../reducers/userSlice";
import { setLoading } from "../../../reducers/loadingSlice";

import { isValidPassword } from "../../utility/validation";

function useOutsideAlerter(ref, setModal) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !ref.current.className.includes("modal__content")
      ) {
        setModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function ResetPassword({
  onClose,
  email,
  setResetPasswordModalVisible,
  resetPasswordModalVisible,
  setModal,
}) {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState(null);
  const [showPassword1, setShowPassword1] = useState(false); // State for first password input field
  const [showPassword2, setShowPassword2] = useState(false);

  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();

  const { token } = useParams();
  // From URL
  let forgotEmail = localStorage.getItem("forgot-email");

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      if (!newPassword || !confirmPassword) {
        toast.error("Password Is Required!");
      } else if (newPassword !== confirmPassword) {
        toast.error("Password Doesn't matched!");
      } else if (!isValidPassword(newPassword)) {
        toast.error(
          "Password must contain at least one digit, one lowercase, one uppercase letter,one special character and be at least 6 characters long."
        );
      } else {
        const data = await axios
          .post(process.env.REACT_APP_BASE_URL + "/reset-password", {
            token: token,
            password: newPassword,
            email: forgotEmail,
          })
          .then((res) => {
            toast.success("Password Updated Successfully!");
            dispatch(setLoading(false));
            navigate("/");
          });
      }
    } catch (error) {
      dispatch(setLoading(false));

      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    }
  };

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setModal);
  return (
    <>
      <Home />
      <div
        className={`modal modal--loginModal modal--open`}
        id="ReSetPasswordModal"
        role="dialog"
        ref={wrapperRef}
      >
        <div className="modal__dialog modal__dialog--lg">
          <div className="modal__content">
            <div className="loginWrap">
              <div className="loginWrap__item">
                <div className="loginWrap__header">
                  <div className="loginWrap__branding">
                    <div className="logo">
                      <Link to="/">
                        <LOGO />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="loginWrap__form">
                  <div className="loginWrap__titlebox">
                    <h2 className="title">Re-Set Password</h2>
                    <p className="para">
                      To recover your password, enter your new password.
                    </p>
                  </div>

                  <form className="form">
                    <div className="form__group">
                      <label className="form__label">
                        Enter new password
                        <span className="form__label__asterisks">*</span>
                      </label>

                      <div className="form__paswordfield">
                        <i
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            fontStyle: "normal",
                            fontSize: "14px"
                          }}
                          className={`fas ${showPassword1 ? "fa-eye-slash" : "fa-eye"
                            } password-toggle-icon`}
                          onClick={toggleShowPassword1}
                        >
                          Show
                        </i>
                        <input
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          type={showPassword1 ? "text" : "password"}
                          id="password"
                          className="form__input"
                          placeholder="Enter new password"
                        />
                      </div>
                    </div>

                    <div className="form__group">
                      <label className="form__label">
                        Re-Enter new password
                        <span className="form__label__asterisks">*</span>
                      </label>

                      <div className="form__paswordfield">
                        <input
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type={showPassword2 ? "text" : "password"}
                          id="confirmPassword"
                          className="form__input"
                          placeholder={
                            showPassword2
                              ? "Re-Enter new password"
                              : "Re-Enter new password"
                          }
                        />
                        <i
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            fontStyle: "normal",
                            fontSize: "14px"
                          }}
                          className={`fas ${showPassword2 ? "fa-eye-slash" : "fa-eye"
                            } password-toggle-icon`}
                          onClick={toggleShowPassword2}
                        >
                          Show
                        </i>
                      </div>
                    </div>

                    <div className="form__group">
                      {resetError && <p className="error">{resetError}</p>}
                      <div className="form__group">
                        <button
                          className="btn btn--primary btn--full"
                          onClick={handleResetPassword}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {successModal ? <SuccesModal setSuccessModal={setSuccessModal} /> : null}
    </>
  );
}

export default ResetPassword;

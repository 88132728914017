import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { aboutPageContent } from "../../api/contentApi";
import campLogo from "../../parent-assets/images/enrichment-images/camp-icon-23.png";
import OwlCarousel from "react-owl-carousel";
import galleryImg1 from "../../parent-assets/images/Facilities-carousel/Art01.jpg";
import galleryImg2 from "../../parent-assets/images/Facilities-carousel/Art02.jpg";
import galleryImg3 from "../../parent-assets/images/Facilities-carousel/Art03.jpg";
import dummyImage2 from "../../parent-assets/images/Facilities-carousel/Art03.jpg";

import { Fancybox } from "@fancyapps/ui";
import { useEffect, useState } from "react";
import { getCampDetailClassDetails } from "../../api/campClassDetail";
import { getCampProgramClassDetails } from "../../../Admin/adminApi/program";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";
import axios from "axios";
import { toast } from "react-toastify";
import { getWeeklyClassByID, getWeeklyClassForFrontEnd } from "../../../Admin/adminApi/addClassList";
function CampVersion() {
  const { id } = useParams();
  const { state } = useLocation();
  const token = localStorage.getItem("access_token");
  const [dummyArr, setDummyArr] = useState([1, 2, 3, 4, 5, 6]);
  const navigate = useNavigate();
  const storedCampData = localStorage.getItem("campData");
  const [halfDayContentContainer, setHalfDayContentContainer] = useState();
  const [fullDayContentContainer, setFullDayContentContainer] = useState();
  const [campDetailData, setCampDetailData] = useState();
  const [showShimmer, setShowShimmer] = useState(false);
  const [galleryImg, setGalleryImg] = useState();
  const [campDetails, setCampDetails] = useState();
  let campData = [];

  if (storedCampData) {
    campData = JSON.parse(storedCampData);
  }

  const getSmallestDate = (date1, date2) => {
    if (!date1) return date2;
    if (!date2) return date1;
    return new Date(date1) < new Date(date2) ? date1 : date2;
  };

  const getGreatestDate = (date1, date2) => {
    if (!date1) return date2;
    if (!date2) return date1;
    return new Date(date1) > new Date(date2) ? date1 : date2;
  };

  useEffect(() => {
    getGalleryInfoData();
    getCampClassDetailsHandler();
    getWeeks();
    const fancyboxOptions = {};
    Fancybox.bind("[data-fancybox='gallery']", fancyboxOptions);
  }, []);


  const getWeeks = async () => {
    try {
      const postData = {
        classId: parseInt(id)
      }
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/class/weekly-classes`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      setCampDetails(response?.data?.data);
    } catch (error) {
      console.error("Error while generating Weeks", error);
    }
  }
  const getGalleryInfoData = async () => {
    try {
      const postData = {
        facility_id: parseInt(id)
      }
      const responseImage = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/get_gallery`, postData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
      )
      setGalleryImg(responseImage?.data?.data)
    } catch (error) {
      console.error("Error while generating gallery info.", error);
      // setGalleryLoader(false);
    }
  }
  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
  };

  const moreInfoHandler = async (programId, boolValue) => {
    // navigate("/camp/halfday_camp-detail-class");
    try {
      // const response = await getCampDay();

      const response = await getCampDetailClassDetails(
        token,
        programId,
        boolValue
      );
    } catch (error) {

    }
  };
  const fullDayCampHandler = () => {
    navigate("/camp/fullday_camp-detail-class");
  };

  const dateformatHandler = (date) => {
    if (date === null || date === undefined || date === "") {
      return ""
    }
    const months = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    }
      ;

    const splitedDate = date?.split("-");
    return `${months?.[splitedDate?.[1]]} ${splitedDate?.[2]} `

  }
  const getCampClassDetailsHandler = async () => {
    setShowShimmer(true);
    const response = await getCampProgramClassDetails(parseInt(id));

    setCampDetailData(response?.data?.data?.[0])
    setHalfDayContentContainer(
      response?.data?.data?.[0]?.content?.[0]?.first_one
    );
    setFullDayContentContainer(
      response?.data?.data?.[0]?.content?.[1]?.second_one
    );
    setShowShimmer(false);
  };


  const allWeeks = Array.from(
    new Set([
      ...(campDetails?.halfDay?.map(item => item.week) || []),
      ...(campDetails?.fullDay?.map(item => item.week) || []),
    ])
  );
  return (
    <>
      {
        showShimmer ? <AboutUsShimmer /> :
          <div className="contentwraper">
            <section style={{ position: "relative" }}>
              <section className="siteBanner" style={{ background: "#ea519c" }}>
                <div className="siteBanner__overlay">
                  <img
                    className="siteBanner__img"
                    src={campDetailData?.header_image ?
                      campDetailData?.header_image :
                      aboutPageContent.aboutBannerImage.url}
                    alt="banner"
                  />
                </div>
              </section>
            </section>

            {/* BODY  */}
            <section className="breadcrumbSec">
              <div className="container">
                <div className="breadcrumb">
                  <div className="breadcrumb__item">
                    <Link className="breadcrumb__link" onClick={() => navigate(-1)}>
                      Back to results
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            {/* CONTENT  */}

            <section class="aboutusSec camp_sec">
              <div class="container">
                <div
                  className="picture camp_pic"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={campLogo}
                    alt="gymtimeGuy"
                    className="picture__img camp_logo"
                    style={{ height: "100px" }}
                  />
                  <h1 className="title title--withicon title--extrabold camp_title camp_title">
                    {campDetailData?.name}
                  </h1>
                </div>

                <div
                  className="picture class_detail_image_container"
                  style={{ float: "left" }}
                >
                  <img
                    className="picture__img"
                    src={campDetailData?.image ? campDetailData?.image : dummyImage2}
                    alt="CLASS IMAGES"
                  />
                  <p className="class_details_shortText_container">
                    {campDetailData?.subprogramdesc2
                      ? campDetailData?.subprogramdesc2
                      : ` This engaging designed for a summer filled with
                        adventure, discovery, and readiness for school. It's the
                        ideal mix of athleticism and endless enjoyment! Spaces
                        are limited, so secure your spot today and get ready for
                        a memorable summer filled with learning and laughter!`}
                  </p>
                </div>
                {/* LOWER SECRTION  */}

                <div className="aboutData">
                  <div className="half_full_container_fluid">
                    <div className="half_full_heading_title_container">
                      <h1 className="register_title_heading">
                        <span> To Register Call : {campDetailData?.camp_heading_first}</span> <br />
                        <span>Email : {campDetailData?.camp_heading_second} </span>
                      </h1>
                    </div>

                    <div className="half_full_day_container_fluid_center">
                      <div style={{ marginTop: "2rem"}}>
                        <div
                          className="registration_fees_desc"
                          style={{ marginBottom: (campDetails?.halfDay?.length >5 || campDetails?.fullDay.length >5) && "3rem" }}
                        >
                          <div className="half_full_heading_title_container"
                            style={{ marginBottom: campDetails?.halfDay?.length === 0 || campDetails?.fullDay.length ===0 && "2rem" }}
                          >
                            <h1 className="register_title_heading">
                              <span>
                                {" "}
                                {campDetailData?.camp_heading_third}
                              </span>
                            </h1>
                          </div>
                          <div className="registration_fees_desc_para_container">



                          </div>
                        </div>

                        <div className="dummy_container">
                          <div className="half_full_day_schedule_desc">
                            <div className="half_full_box_container">
                              {campDetails?.halfDay.length > 0 && <div className="half_day_box">
                                <div className="half_day_box_inner_container">
                                  <h1 className="half_day_schedule_title_heading">
                                    Half Day Schedule
                                  </h1>

                                  <p
                                    style={{ color: "white" }}
                                    dangerouslySetInnerHTML={{
                                      __html: halfDayContentContainer,
                                    }}
                                  ></p>
                                </div>
                              </div>}
                              {campDetails?.fullDay?.length > 0 && <div className="full_day_box">
                                <div className="half_day_box_inner_container">
                                  <h1 className="half_day_schedule_title_heading">
                                    Full Day Schedule
                                  </h1>
                                  <p
                                    style={{ color: "white" }}
                                    dangerouslySetInnerHTML={{
                                      __html: fullDayContentContainer,
                                    }}
                                  ></p>
                                </div>
                              </div>}
                            </div>
                            <div
                              className="half_full_weekly_desc">
                              <div className="weekly_description">
                                <h1 className="weekly_desc_head_title">
                                  Weekly Camp Themes
                                </h1>
                                <span className="weekly_schedule">

                                  {dateformatHandler(getSmallestDate(campDetails?.halfDay?.[0]?.start_date, campDetails?.fullDay?.[0]?.start_date))} -
                                  {dateformatHandler(getGreatestDate(campDetails?.halfDay?.[campDetails?.halfDay?.length - 1]?.end_date, campDetails?.fullDay?.[campDetails?.fullDay?.length - 1]?.end_date))}
                                </span>
                              </div>



                              {allWeeks.map((week, index) => {
                                const halfDayMatch = campDetails?.halfDay?.find(item => item.week === week);
                                const fullDayMatch = campDetails?.fullDay?.find(item => item.week === week);

                                return (
                                  <div key={index}>
                                    <div className="weekly_description_info">
                                      <div className="weekday-count-name">
                                        <div className="week_day_count">
                                          <span>{week}</span>
                                          <span className="camp_week_days_date">
                                            {`${dateformatHandler(getSmallestDate(halfDayMatch?.start_date, fullDayMatch?.start_date))} 
                                            - 
                                            ${dateformatHandler(getGreatestDate(halfDayMatch?.end_date, fullDayMatch?.end_date))}`}
                                          </span>
                                        </div>
                                        <div className="week_day_name">
                                          {(halfDayMatch?.tuition || halfDayMatch?.tuition == 0) && <span>Half day ${halfDayMatch.tuition} 
                                            {(halfDayMatch?.tuition || halfDayMatch?.tuition == 0) && 
                                            (fullDayMatch?.tuition || fullDayMatch?.tuition == 0)
                                            &&
                                            " or "} 
                                            </span>}
                                          {(fullDayMatch?.tuition || fullDayMatch?.tuition == 0) && <span>  Full day ${fullDayMatch.tuition}</span>}
                                        </div>
                                        <div className="week_day_name">
                                          <span>{halfDayMatch?.name || fullDayMatch?.name}</span>
                                        </div>
                                      </div>
                                      <div className="week_day_date_schedule"></div>
                                    </div>
                                    <div className="week_end_line"></div>
                                  </div>
                                );
                              })}


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CARUOSEL  */}
                <section className="facilitiesSec">
                  <div className="container">
                    <h1 className="title title--extrabold">Take a Look!</h1>
                    <OwlCarousel
                      margin={10}
                      responsiveclassname="true"
                      responsive={responsiveOptions}
                      className="galleryGrid owl-carousel"
                      autoplay
                      loop={true}
                      arrows="true"
                      dots={false}
                      nav={true}
                      lazyLoad={true}
                      navText={[
                        `<img src=${require("../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                        `<img src=${require("../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                      ]}
                    >
                      {
                        galleryImg?.map((item) => (
                          <div className="galleryGrid__item">
                            <div className="galleryGrid__card">
                              <a
                                className="galleryGrid__card__link"
                                data-fancybox="gallery"
                                href="../images/gallery-img01.png"
                              >
                                <img
                                  className="galleryGrid__card__img"
                                  src={item?.image}
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        ))
                      }

                    </OwlCarousel>
                  </div>
                </section>
              </div>
            </section>
          </div>
      }
    </>
  );
}

export default CampVersion;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getPrograms from "../../adminApi/categories";
import {
  createCampHalfFullDayWeeklyClass,
  createClassAPI,
  editCampHalfFullDayWeeklyClass,
  editClassAPI,
} from "../../adminApi/classApi";
import DatePicker from "react-datepicker";

// Mulit Seelct Pkage
import LoaderCompnent from "../LoaderComponent";
import { getTeachersList } from "../../adminApi/teachers";
import { ReactComponent as DeleteCardIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import CampClassTable from "./CampClassTable";
import {
  customStyles,
  dateFormatterGMT,
  days,
  formatDateWithoutNew,
  formatDateWithoutNewPrefilled,
} from "../../adminUtility";
import Select from "react-select";
import { isValidPhoneNumber } from "../../../Parent/utility/validation";
import ComboClassTable from "./ComboClass.jsx";

// Camp Class Function

function AddNewClass({
  setAddNewClasses,
  openAddNewClass,
  type,
  data,
  fetchData,
  getClassData,
  sortKey,
  setSortKey,
  searchedParam,
  setSearchedParam,
  setIsClassEdited,
}) {
  const [campId, setCampId] = useState(data?.id);
  const token = localStorage.getItem("access_token");
  const [classType, setClassType] = useState(
    data?.period === "Weekly" ? "WEEKLY" : "MONTHLY"
  );
  const [isNormalClass, setIsNormalClass] = useState("NORMAL");
  const [selectedCategory, setSelectedCategory] = useState();
  const [getTeacherData, setGetTeacherDats] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [campType, setCampType] = useState("half_day");
  // table
  const [categoryApiData, setGetCategoryApiData] = useState();
  const [formData, setFormData] = useState({
    name: "",
    categoryName: "",
  });
  const [selected, setSelected] = useState([]);
  const [selectedCategoriesNew, setSelectedCategoriesNew] = useState([]);
  // Camp State
  const [campRows, setCampRows] = useState([
    {
      half_day_fk_teacher_id: "",
      half_day_start_date: "",
      half_day_end_date: "",
      half_day_name: "",
      half_day_tuition: 0,
      half_day_max_strength: "",
      id: "",
    },
  ]);

  const [fullDayCampRow, setFullDayCampRow] = useState([
    {
      full_day_fk_teacher_id: "",
      full_day_start_date: "",
      full_day_end_date: "",
      full_day_name: "",
      full_day_tuition: 0,
      full_day_max_strength: "",
      id: "",
    },
  ]);

  const [campTime, setCampTime] = useState({
    half_day_start_time: "",
    half_day_end_time: "",
  });

  const [fullDayCampTime, setFullDayCampTime] = useState({
    full_day_start_time: "",
    full_day_end_time: "",
  });

  const [campDay, setCampDay] = useState({
    half_day: data?.halfDay?.length > 0 || type === "Add" ? true : false,
    full_day: data?.fullDay?.length > 0 ? true : false,
  });

  // Add teacher form logic states
  const [addRows, setAddRows] = useState([
    {
      teacher: "",
      weekday: "",
      startTime: "",
      endTime: "",
      subject: "",
      max_strength: "",
      id: "",
    },
  ]);

  // COMBO CLASS STATE ARRAY ::
  const [comboRows, setComboRows] = useState([
    {
      teacher: "",
      weekday: "",
      startTime: "",
      endTime: "",
      subject: "",
      maxStudents: "",
      id: "",
    },
  ]);

  const handleAddCampRow = () => {
    if (campType === "half_day") {
      setCampRows([
        ...campRows,
        {
          fk_teacher_id: "",
          start_date: "",
          end_date: "",
          week: "",
          tuition: 0,
          max_strength: "",
          id: "",
        },
      ]);
    }
    if ("full_day") {
      setFullDayCampRow([
        ...fullDayCampRow,
        {
          fk_teacher_id: "",
          start_date: "",
          end_date: "",
          week: "",
          tuition: 0,
          max_strength: "",
          id: "",
        },
      ]);
    }
  };
  // Handle Add Combo Class Rows
  const handleAddComboClass = () => {
    setComboRows([
      ...comboRows,
      {
        teacher: "",
        weekday: "",
        startTime: "",
        endTime: "",
        subject: "",
        maxStudents: "",
        id: "",
      },
    ]);
  };
  // Handle Delete Combo Rows
  const handleDeleteCampRow = (index, CAMP_TYPE) => {
    if (CAMP_TYPE === "halfDay") {
      const newCampRows = [...campRows];
      newCampRows.splice(index, 1);
      setCampRows(newCampRows);
    }
    if (CAMP_TYPE === "fullDay") {
      const newFullCampRow = [...fullDayCampRow];
      newFullCampRow?.splice(index, 1);
      setFullDayCampRow(newFullCampRow);
    }
  };

  const handleDeleteComboClass = (index) => {
    const updatedRows = comboRows.filter((_, i) => i !== index);
    setComboRows(updatedRows);
  };

  const handleChangeCamp = (index, name, value) => {
    if (campType === "half_day") {
      let updatedRows = [...campRows];

      if (name === "half_day_end_date" || name === "half_day_start_date") {
        const updatedDate = dateFormatterGMT(value);
        updatedRows[index][name] = updatedDate;

        const startDate = new Date(updatedRows[index].start_date).getTime();
        const endDate = new Date(updatedRows[index].end_date).getTime();
        if (endDate <= startDate) {
          toast.error(
            "End date must be greater than start date for row " + (index + 1)
          );
          return;
        }
      } else {
        updatedRows[index][name] = value;
      }

      // Update state after all modifications
      setCampRows(updatedRows);
    }
  };

  const handleChangeCampFullDay = (index, name, value) => {
    let updatedRows = [...fullDayCampRow];

    if (name === "full_day_end_date" || name === "full_day_start_date") {
      const updatedDate = dateFormatterGMT(value);
      updatedRows[index][name] = updatedDate;

      const startDate = new Date(updatedRows[index].start_date).getTime();
      const endDate = new Date(updatedRows[index].end_date).getTime();
      if (endDate <= startDate) {
        toast.error(
          "End date must be greater than start date for row " + (index + 1)
        );
        return;
      }
    } else {
      updatedRows[index][name] = value;
    }

    // Update state after all modifications
    setFullDayCampRow(updatedRows);
  };

  const handleChangeComboClass = (index, name, value) => {
    if (name === "weekStartDate" || name === "weekEndDate") {
      const dateValue = value;
      const updatedRows = comboRows.map((row, rowIndex) => {
        if (index === rowIndex) {
          return { ...row, [name]: dateValue };
        }
        return row;
      });
      setComboRows(updatedRows);

      const startDate = new Date(updatedRows[index].weekStartDate).getTime();
      const endDate = new Date(updatedRows[index].weekEndDate).getTime();
      if (endDate <= startDate) {
        toast.error(
          "End date must be greater than start date for row " + (index + 1)
        );
      }
    } else {
      const updatedRows = comboRows.map((row, rowIndex) => {
        if (index === rowIndex) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setComboRows(updatedRows);
    }
  };
  // SCHEDULE STATE
  const [classScheduleDetailsData, setClassScheduleDetailsData] = useState([
    {
      fk_teacher_class_schedule: 0,
      weekday: "",
      start_time: "",
      end_time: "",
    },
  ]);
  // DATE FOMRATE FUNCTIOn

  const [editData, setEditData] = useState({
    season: data?.season_name,
    gender: data?.gender,
    start_date: data?.start_date,
    end_date: data?.end_date,
    weekday: "Tuesday",
    className: data?.name,
    category: data?.category,
    websiteGroup: data?.website,
    minAge: data?.min_age,
    maxAge: data?.max_age,
    period: data?.period,
    hidden: data?.hidden,
    maxStrength: data?.max_strength,
    tuition: data?.tuition,
    classScheduleDetails: [
      {
        fk_teacher_class_schedule: 5,
        weekday: "",
        start_time: "",
        end_time: "",
      },
    ],
  });

  const [classFormData, setclassFormData] = useState({
    season: "",
    gender: "",
    start_date: "",
    end_date: "",
    weekday: "",
    className: "",
    totalTuition: "",
    websiteGroup: "",
    minAge: "",
    maxAge: "",
    period: "Monthly",
    max_strength: "",
    tuition: "",
    hidden: false,
    teachers: [],
    classScheduleDetails: [
      {
        fk_teacher_class_schedule: "",
        weekday: "",
        start_time: "",
        end_time: "",
      },
    ],
  });

  // Camp Day
  const handleCampDay = (e, camp_type) => {
    if (camp_type === "half_day") {
      setCampType(camp_type);
      if (e.target.checked) {
        setCampDay((prevData) => ({
          ...prevData,
          half_day: true,
        }));
      } else {
        setCampDay((prevData) => ({
          ...prevData,
          half_day: false,
        }));
      }
    } else {
      if (e.target.checked) {
        setCampType(camp_type);
        setCampDay((prevData) => ({
          ...prevData,
          full_day: true,
        }));
      } else {
        setCampDay((prevData) => ({
          ...prevData,
          full_day: false,
        }));
      }
    }
    // setCampDay(event.target.value);
  };

  useEffect(() => {
    if (data?.schedules) {
      setClassScheduleDetailsData({
        ...classScheduleDetailsData,
        classScheduleDetails: data?.schedules?.map((val, index) => {
          return {
            fk_teacher_class_schedule: val?.teacherId,
            weekday: val?.weekday,
            start_time: val?.start_time,
            end_time: val?.end_time,
          };
        }),
      });

      setSelected(
        data.schedules?.map((temp) => {
          return {
            label: temp.name,
            value: temp?.teacherId,
            startTime: temp?.start_time,
            endTime: temp?.end_time,
            weekday: temp?.weekday,
            days: [
              { label: "Monday", value: "Monday" },
              { label: "Tuesday", value: "Tuesday" },
              { label: "Wednesday", value: "Wednesday" },
              { label: "Thursday", value: "Thursday" },
              { label: "Friday", value: "Friday" },
              { label: "Saturday", value: "Saturday" },
              { label: "Sunday", value: "Sunday" },
            ],
          };
        })
      );

      categoryApiData?.map((item) =>
        setSelectedCategoriesNew((prev) => [...prev, item?.id])
      );
    }
  }, [data]);

  // Tabl DATA Prfilled
  const existingSchedules = data?.schedules || [];
  const comboSchdule = data?.schedules || [];
  const campSchedule = data?.halfDay || [];
  const campFullSchedule = data?.fullDay || [];

  useEffect(() => {
    // NORMAL CLASS
    if (existingSchedules && existingSchedules.length > 0) {
      const mappedSchedules = existingSchedules.map((schedule) => ({
        subject: schedule?.subject || "",
        teacher: schedule?.teacherId || "",
        weekday: schedule?.weekday || "",
        startTime: schedule?.start_time || "",
        endTime: schedule?.end_time || "",
        max_strength: schedule?.max_strength || "",
        id: schedule?.id || "",
      }));
      setAddRows(mappedSchedules);
    }
    // COMBO SCHUL
    if (comboSchdule && comboSchdule.length > 0) {
      const mappedSchedules = comboSchdule?.map((schedule) => ({
        subject: schedule?.subject || "",
        teacher: schedule?.teacherId || "",
        weekday: schedule?.weekday || "",
        startTime: schedule?.start_time || "",
        endTime: schedule?.end_time || "",
        maxStudents: schedule?.max_strength,
        id: parseInt(schedule?.id),
      }));
      setComboRows(mappedSchedules);
    }
    // // Camp Class
    if (existingSchedules && existingSchedules.length > 0) {
      const mappedSchedules = existingSchedules.map((schedule) => ({
        subject: schedule?.subject || "",
        teacher: schedule?.teacherId || "",
        weekday: schedule?.weekday || "",
        startTime: schedule?.start_time || "",
        endTime: schedule?.end_time || "",
        max_strength: schedule?.max_strength || "",
        id: schedule?.id || "",
      }));
      setAddRows(mappedSchedules);
    }
    // HALF DAY
    if (campSchedule && campSchedule.length > 0) {
      const mappedSchedules = campSchedule?.map((schedule) => ({
        half_day_fk_teacher_id: schedule?.fk_teacher_id || "",
        half_day_start_date: schedule?.start_date || "",
        half_day_end_date: schedule?.end_date || "",
        half_day_name: schedule?.name || "",
        half_day_tuition: schedule?.tuition,
        half_day_max_strength: schedule?.max_strength || "",
        id: schedule?.id,
      }));

      setCampRows(mappedSchedules);

      const mappedTimeSchedules = campSchedule?.map((schedule) => ({
        half_day_start_time: schedule?.start_time || "",
        half_day_end_time: schedule?.end_time || "",
      }));
      setCampTime((prevState) => ({
        ...prevState,
        half_day_start_time: mappedTimeSchedules?.[0]?.half_day_start_time,
        half_day_end_time: mappedTimeSchedules?.[0]?.half_day_end_time,
      }));
      // setCampTime(mappedTimeSchedules);
    }
    if (campFullSchedule && campFullSchedule?.length > 0) {
      const mappedFullSchedules = campFullSchedule?.map((schedule) => ({
        full_day_fk_teacher_id: schedule?.fk_teacher_id || "",
        full_day_start_date: schedule?.start_date || "",
        full_day_end_date: schedule?.end_date || "",
        full_day_name: schedule?.name || "",
        full_day_tuition: schedule?.tuition,
        full_day_max_strength: schedule?.max_strength || "",
        id: schedule?.id,
      }));

      setFullDayCampRow(mappedFullSchedules);

      const mappedTimeSchedules = campFullSchedule?.map((schedule) => ({
        full_day_start_time: schedule?.start_time || "",
        full_day_end_time: schedule?.end_time || "",
      }));
      setFullDayCampTime((prevState) => ({
        ...prevState,
        full_day_start_time: mappedTimeSchedules?.[0]?.full_day_start_time,
        full_day_end_time: mappedTimeSchedules?.[0]?.full_day_end_time,
      }));
      // setFullDayCampTime(mappedTimeSchedules);
    }
  }, [existingSchedules]);

  // GET TEACHERS
  useEffect(() => {
    const getTeacherApi = async () => {
      const token = localStorage.getItem("access_token");
      const formData = [];
      const data = await getTeachersList(token, formData, 0, sortKey, "300000");
      setGetTeacherDats(data?.getTeachers?.data);
    };
    getTeacherApi();
  }, []);

  const validateForm = () => {
    const {
      season,
      gender,
      start_date,
      end_date,

      className,
      websiteGroup,
      minAge,
      maxAge,
      period,
    } = classFormData;

    if (
      !season &&
      !gender &&
      !start_date &&
      !end_date &&
      !className &&
      !websiteGroup &&
      !minAge &&
      !maxAge &&
      !period
    ) {
      toast.error("Enter all the fields");
      return false;
    }

    const requiredFields = {
      season: "Season",
      gender: "Gender",
      start_date: "Start Date",
      end_date: "End Date",
      // weekday: "Day of the Week",
      teacher: "Teacher",
      className: "Class Name",
      websiteGroup: "Website Group",
      minAge: "Min Age ",
      maxAge: "Max Age ",
      period: "Period",
    };

    const emptyFields = Object.keys(requiredFields).filter(
      (field) => classFormData[field] === ""
    );

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map((field) => requiredFields[field]);
      const errorMessage = `Please fill in the following fields: ${emptyFieldNames.join(
        ", "
      )}`;
      toast.error(errorMessage);
      return false;
    }

    return true;
  };

  const handleDateChange = (date, name) => {
    setclassFormData({ ...classFormData, [name]: date });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "season") {
      setclassFormData({ ...classFormData, [name]: value });
    }

    if (name === "phone") {
      const isValidPhone = isValidPhoneNumber(value);
      if (!isValidPhone) {
        toast.error("Invalid phone number", {
          toastId: "error",
        });
        return;
      }
    }

    if (name === "start_date" || name === "end_date") {
      setclassFormData({ ...classFormData, [name]: value });
      return;
    }

    if (type === "Add") {
      setclassFormData({ ...classFormData, [name]: value });
    } else {
      setEditData({ ...editData, [name]: value });
      setclassFormData({
        ...classFormData,
        [name]: value,
      });
    }

    setclassFormData({
      ...classFormData,
      [name]: value,
    });
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (classType == "WEEKLY") {
      // VALIDATION FOR CAMP

      if (classFormData?.season == "") {
        toast.error("Season is required!");
        return;
      } else if (classFormData?.gender == "") {
        toast.error("Gender is required!");
        return;
      } else if (campTime?.startTime == "") {
        toast.error("Camp start time is required!");
        return;
      } else if (campTime?.endTime == "") {
        toast.error("Camp end time is required!");
        return;
      } else if (classFormData?.className == "") {
        toast.error("Camp Name is required!");
        return;
      } else if (classFormData?.maxAge == "") {
        toast.error("Max age is required!");
        return;
      } else if (classFormData?.minAge == "") {
        toast.error("Min age is required!");
        return;
      } else if (classFormData?.websiteGroup == "") {
        toast.error("Website group is required!");
        return;
      } else {
        // API CALLED
        const token = localStorage.getItem("access_token");
        setIsLoading(false);
      }

      try {
        try {
          if (type === "Add") {
            // ====================================================Half day and full day start time validation=======================================
            if (
              campDay?.half_day &&
              (campTime?.half_day_start_time === undefined ||
                campTime?.half_day_start_time === null ||
                campTime?.half_day_start_time === "")
            ) {
              toast.error(`Halfday start time is the required field`);
              return;
            } else if (
              campDay?.half_day &&
              (campTime?.half_day_end_time === undefined ||
                campTime?.half_day_end_time === null ||
                campTime?.half_day_end_time === "")
            ) {
              toast.error(`Halfday end time is the required field`);
              return;
            } else if (
              campDay?.full_day &&
              (fullDayCampTime?.full_day_start_time === undefined ||
                fullDayCampTime?.full_day_start_time === null ||
                fullDayCampTime?.full_day_start_time === "")
            ) {
              toast.error(`Fullday start time is the required field`);
              return;
            } else if (
              campDay?.full_day &&
              (fullDayCampTime?.full_day_end_time === undefined ||
                fullDayCampTime?.full_day_end_time === null ||
                fullDayCampTime?.full_day_end_time === "")
            ) {
              toast.error(`Fullday end time is the required field`);
              return;
            }

            // ==========================================Half day validation=================
            if (campDay?.half_day) {
              for (let i = 0; i < campRows?.length; i++) {
                if (
                  campRows?.[i]?.half_day_start_date === "" ||
                  campRows?.[i]?.half_day_start_date === null ||
                  campRows?.[i]?.half_day_start_date === undefined
                ) {
                  toast.error(`Halfday Start date in ${i + 1} row is missing.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_end_date === "" ||
                  campRows?.[i]?.half_day_end_date === null ||
                  campRows?.[i]?.half_day_end_date === undefined
                ) {
                  toast.error(`Half End date in ${i + 1} row is missing.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_name === "" ||
                  campRows?.[i]?.half_day_name === null ||
                  campRows?.[i]?.half_day_name === undefined
                ) {
                  toast.error(`Weekday name is missig in ${i + 1} row.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_max_strength === "" ||
                  campRows?.[i]?.half_day_max_strength === null ||
                  campRows?.[i]?.half_day_max_strength === undefined
                ) {
                  toast.error(`Max student is missing in ${i + 1} row.`);
                  return;
                }
                if (
                
                  campRows?.[i]?.half_day_tuition === null ||
                  campRows?.[i]?.half_day_tuition === undefined 
                ) {
                  console.log("tuition",campRows?.[i]?.half_day_tuition);
                  toast.error(`Half day tuition is missing in ${i + 1} row`);
                  return;
                }
              }
            }

            // ================================================Full day validation============================================
            if (campDay?.full_day) {
              for (let i = 0; i < fullDayCampRow.length; i++) {
                if (
                  fullDayCampRow?.[i]?.full_day_start_date === "" ||
                  fullDayCampRow?.[i]?.full_day_start_date === null ||
                  fullDayCampRow?.[i]?.full_day_start_date === undefined
                ) {
                  toast.error(
                    `Full day start date is missing in the ${i + 1} row.`
                  );
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_end_date === "" ||
                  fullDayCampRow?.[i]?.full_day_end_date === null ||
                  fullDayCampRow?.[i]?.full_day_end_date === undefined
                ) {
                  toast.error(
                    `Full day end date is missing in the ${i + 1} row.`
                  );
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_name === "" ||
                  fullDayCampRow?.[i]?.full_day_name === null ||
                  fullDayCampRow?.[i]?.full_day_name === undefined
                ) {
                  toast.error(`Weekday name is missing in ${i + 1} row.`);
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_max_strength === "" ||
                  fullDayCampRow?.[i]?.full_day_max_strength === null ||
                  fullDayCampRow?.[i]?.full_day_max_strength === undefined
                ) {
                  toast.error(
                    `Fullday max strength is missing in ${i + 1} row.`
                  );
                  return;
                }
                if (
                 
                  fullDayCampRow?.[i]?.full_day_tuition === null ||
                  fullDayCampRow?.[i]?.full_day_tuition === undefined
                ) {
                  toast.error(`Full day tuition is missing in ${i + 1} row`);
                  return;
                }
              }
            }
          
            const token = localStorage.getItem("access_token");
            const data = {
              classDetails: {
                fk_season_classes: classFormData?.season,
                program_name: "Camp",
                program_sub_name: classFormData?.className,
                fk_category_id: [selectedCategory?.value],
                max_age: classFormData?.maxAge,
                min_age: classFormData?.minAge,
                website: classFormData?.websiteGroup,
                gender: classFormData?.gender,
                period: "Weekly",
                hidden: false,
              },
               halfDay:
              campDay.half_day ?  (campRows?.length === 1 &&
                  campRows?.[0]?.half_day_fk_teacher_id === "" &&
                  campRows?.[0]?.half_day_start_date === "" &&
                  campRows?.[0]?.half_day_end_date === "" &&
                  campRows?.[0]?.half_day_name === "" &&
                  campRows?.[0]?.half_day_max_strength === ""
                  ? {
                    campDetails: [],
                  }
                  : {
                    half_day_start_time: campTime?.half_day_start_time,
                    half_day_end_time: campTime?.half_day_end_time,
                    campDetails: campRows,
                  }) : null,
              fullDay:
              campDay.full_day ? (fullDayCampRow?.length === 1 &&
                  fullDayCampRow?.[0]?.full_day_end_date === "" &&
                  fullDayCampRow?.[0]?.full_day_fk_teacher_id === "" &&
                  fullDayCampRow?.[0]?.full_day_max_strength === "" &&
                  fullDayCampRow?.[0]?.full_day_name === "" &&
                  fullDayCampRow?.[0]?.full_day_start_date === ""
                  ? {
                    campDetails: [],
                  }
                  : {
                    full_day_start_time: fullDayCampTime?.full_day_start_time,
                    full_day_end_time: fullDayCampTime?.full_day_end_time,
                    campDetails: fullDayCampRow,
                  }) : null,
            };
            setIsLoading(true);
            const campWeeklyClass = await createCampHalfFullDayWeeklyClass(
              token,
              data
            );
            if (
              campWeeklyClass?.status === 200 ||
              campWeeklyClass?.status === 201
            ) {
                setIsLoading(false);
              toast.success(campWeeklyClass?.data?.data);
              setIsClassEdited(true);
              setAddNewClasses(false);
              const updatedFormData = [...searchedParam];
              updatedFormData[0].selectUser = "empty";
              updatedFormData[0].name = "";
              setSearchedParam(updatedFormData);
              fetchData();
            } else {
              setIsLoading(false);
              setAddNewClasses(false);
            }
          }
        } catch (error) {
          setIsLoading(false);
          setAddNewClasses(false);
          toast.error(error);
        }
        try {
          if (type === "Edit") {
            if (
              campDay?.half_day &&
              (campTime?.half_day_start_time === undefined ||
                campTime?.half_day_start_time === null ||
                campTime?.half_day_start_time === "")
            ) {
              toast.error(`Halfday start time is the required field`);
              return;
            } else if (
              campDay?.half_day &&
              (campTime?.half_day_end_time === undefined ||
                campTime?.half_day_end_time === null ||
                campTime?.half_day_end_time === "")
            ) {
              toast.error(`Halfday end time is the required field`);
              return;
            } else if (
              campDay?.full_day &&
              (fullDayCampTime?.full_day_start_time === undefined ||
                fullDayCampTime?.full_day_start_time === null ||
                fullDayCampTime?.full_day_start_time === "")
            ) {
              toast.error(`Fullday start time is the required field`);
              return;
            } else if (
              campDay?.full_day &&
              (fullDayCampTime?.full_day_end_time === undefined ||
                fullDayCampTime?.full_day_end_time === null ||
                fullDayCampTime?.full_day_end_time === "")
            ) {
              toast.error(`Fullday end time is the required field`);
              return;
            }

            // ==========================================Half day validation=================
            if (campDay?.half_day) {
              for (let i = 0; i < campRows?.length; i++) {
                // if (
                //   campRows?.[i]?.half_day_fk_teacher_id === "" ||
                //   campRows?.[i]?.half_day_fk_teacher_id === null ||
                //   campRows?.[i]?.half_day_fk_teacher_id === undefined
                // ) {
                //   toast.error(`Teacher in the ${i + 1} row is missing.`);
                //   return;
                // }
                if (
                  campRows?.[i]?.half_day_start_date === "" ||
                  campRows?.[i]?.half_day_start_date === null ||
                  campRows?.[i]?.half_day_start_date === undefined
                ) {
                  toast.error(`Halfday Start date in ${i + 1} row is missing.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_end_date === "" ||
                  campRows?.[i]?.half_day_end_date === null ||
                  campRows?.[i]?.half_day_end_date === undefined
                ) {
                  toast.error(`Half End date in ${i + 1} row is missing.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_name === "" ||
                  campRows?.[i]?.half_day_name === null ||
                  campRows?.[i]?.half_day_name === undefined
                ) {
                  toast.error(`Weekday name is missig in ${i + 1} row.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_max_strength === "" ||
                  campRows?.[i]?.half_day_max_strength === null ||
                  campRows?.[i]?.half_day_max_strength === undefined
                ) {
                  toast.error(`Max student is missing in ${i + 1} row.`);
                  return;
                }
                if (
                  campRows?.[i]?.half_day_tuition === "" ||
                  campRows?.[i]?.half_day_tuition === null ||
                  campRows?.[i]?.half_day_tuition === undefined
                ) {
                  console.log("tuition",campRows?.[i]?.half_day_tuition);
                  toast.error(`Half day tuition is missing in ${i + 1} row`);
                  return;
                }
              }
            }

            // ================================================Full day validation============================================
            if (campDay?.full_day) {
              for (let i = 0; i < fullDayCampRow.length; i++) {
                // if (
                //   fullDayCampRow?.[i]?.full_day_fk_teacher_id === "" ||
                //   fullDayCampRow?.[i]?.full_day_fk_teacher_id === null ||
                //   fullDayCampRow?.[i]?.full_day_fk_teacher_id === undefined
                // ) {
                //   toast.error(
                //     `Full day teacher is missing in the ${i + 1} row`
                //   );
                //   return;
                // }
                if (
                  fullDayCampRow?.[i]?.full_day_start_date === "" ||
                  fullDayCampRow?.[i]?.full_day_start_date === null ||
                  fullDayCampRow?.[i]?.full_day_start_date === undefined
                ) {
                  toast.error(
                    `Full day start date is missing in the ${i + 1} row.`
                  );
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_end_date === "" ||
                  fullDayCampRow?.[i]?.full_day_end_date === null ||
                  fullDayCampRow?.[i]?.full_day_end_date === undefined
                ) {
                  toast.error(
                    `Full day end date is missing in the ${i + 1} row.`
                  );
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_name === "" ||
                  fullDayCampRow?.[i]?.full_day_name === null ||
                  fullDayCampRow?.[i]?.full_day_name === undefined
                ) {
                  toast.error(`Weekday name is missing in ${i + 1} row.`);
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_max_strength === "" ||
                  fullDayCampRow?.[i]?.full_day_max_strength === null ||
                  fullDayCampRow?.[i]?.full_day_max_strength === undefined
                ) {
                  toast.error(
                    `Fullday max student is missing in ${i + 1} row.`
                  );
                  return;
                }
                if (
                  fullDayCampRow?.[i]?.full_day_tuition === "" ||
                  fullDayCampRow?.[i]?.full_day_tuition === null ||
                  fullDayCampRow?.[i]?.full_day_tuition === undefined
                ) {
                  toast.error(`Full day tuition is missing in ${i + 1} row`);
                  return;
                }
              }
            }

            const data = {
              classDetails: {
                fk_season_classes: classFormData?.season,
                program_name: "Camp",
                program_sub_name: classFormData?.className,
                fk_category_id: [selectedCategory?.value],
                max_age: classFormData?.maxAge,
                min_age: classFormData?.minAge,
                website: classFormData?.websiteGroup,
                gender: classFormData?.gender,
                period: "Weekly",
                hidden: false,
              },
              halfDay:
               campDay.half_day ? (campRows?.length === 1 &&
                  campRows[0]?.half_day_fk_teacher_id === "" &&
                  campRows[0]?.half_day_start_date === "" &&
                  campRows[0]?.half_day_end_date === "" &&
                  campRows[0]?.half_day_name === "" &&
                  campRows[0]?.half_day_max_strength === ""
                  ? {
                    campDetails: [],
                  }
                  : {
                    half_day_start_time: campTime?.half_day_start_time,
                    half_day_end_time: campTime?.half_day_end_time,
                    campDetails: campRows,
                  }):null,
              fullDay:
              campDay.full_day ?(fullDayCampRow?.length === 1   &&
                  fullDayCampRow[0]?.full_day_end_date === "" &&
                  fullDayCampRow[0]?.full_day_fk_teacher_id === "" &&
                  fullDayCampRow[0]?.full_day_max_strength === "" &&
                  fullDayCampRow[0]?.full_day_name === "" &&
                  fullDayCampRow[0]?.full_day_start_date === ""
                  ? {
                    campDetails: [],
                  }
                  : {
                    full_day_start_time: fullDayCampTime?.full_day_start_time,
                    full_day_end_time: fullDayCampTime?.full_day_end_time,
                    campDetails: fullDayCampRow,
                  }) :null,
            };
            setIsLoading(true);
            const campEditWeeklyClass = await editCampHalfFullDayWeeklyClass(
              campId,
              token,
              data
            );
            if (campEditWeeklyClass?.response?.data?.data !== null) {
              setAddNewClasses(false);
              setIsLoading(false);
              toast.success(campEditWeeklyClass?.data?.data);
              setIsClassEdited(true);
              
              fetchData();
            } else {
              toast.error(campEditWeeklyClass.response.data.error);
              setIsLoading(false);
              return;
            }
          }
        } catch (error) {
          setIsLoading(false);
          toast.error(error);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error);
        return;
      }
    } else {
      const isFormValid = validateForm();

      if (isFormValid) {
        const { start_date, end_date, maxAge, minAge } = classFormData;

        if (type === "Add") {
          if (
            selectedCategory === undefined ||
            Object.keys(selectedCategory).length == 0
          ) {
            toast.error("Select category!", {
              toastId: "error",
            });
            return;
          }
          if (start_date === end_date) {
            toast.error("Start date and end date cannot be the same", {
              toastId: "error",
            });
            return;
          }
          if (new Date(end_date) < new Date(start_date)) {
            toast.error("End date cannot be smaller than start date", {
              toastId: "error",
            });
            return;
          }
          if (parseInt(minAge) > parseInt(maxAge)) {
            toast.error("Min age is always less than Max age", {
              toastId: "error",
            });
            return;
          }

          if (classFormData?.maxAge > 3000) {
            toast.error("Maximum age cannot be more than 3000 months", {
              toastId: "error",
            });
            return;
          }

          if (classFormData?.websiteGroup == "") {
            toast.error("Select website group.", {
              toastId: "error",
            });
          }
        }
        const classScheduleDetails = addRows.map((scheduleData) => ({
          fk_teacher_class_schedule: scheduleData?.teacher,
          weekday: scheduleData?.weekday,
          start_time: scheduleData?.startTime,
          end_time: scheduleData?.endTime,
          id: parseInt(scheduleData?.id),
        }));

        const comboClassDetails = comboRows.map((scheduleData) => ({
          fk_teacher_class_schedule: scheduleData?.teacher,
          subject: scheduleData?.subject,
          weekday: scheduleData?.weekday,
          start_time: scheduleData?.startTime,
          end_time: scheduleData?.endTime,
          max_strength: scheduleData?.maxStudents,
          id: scheduleData?.id,
        }));

        // Edit Payload
        const editPayload = {
          classDetails: {
            fk_season_classes: classFormData?.season,
            name: classFormData?.className,
            fk_category_classes: [selectedCategory?.value],
            tuition: classFormData?.tuition,
            max_age: classFormData?.maxAge,
            min_age: classFormData?.minAge,
            website: classFormData?.websiteGroup,
            max_strength: classFormData?.max_strength,
            gender: classFormData?.gender,
            period: classFormData?.period,
            start_date: formatDateWithoutNew(classFormData?.start_date),
            end_date: formatDateWithoutNew(classFormData?.end_date),
            hidden: classFormData?.hidden,
            weekday: "Monday",
            is_combo: isNormalClass === "NORMAL" ? false : true,
          },
          classScheduleDetails:
            isNormalClass === "NORMAL"
              ? classScheduleDetails
              : comboClassDetails,
        };
        if (isNormalClass !== "CAMP" && (classFormData?.max_strength == 0 || undefined || "")) {
          toast.error("Max Students Should be Greate than 0!");
          return;
        }
        setIsLoading(true);
        const token = localStorage.getItem("access_token");

        if (type === "Edit") {
          try {
            setIsLoading(true);
            const updatedData = await editClassAPI(
              token,
              data?.id,
              editPayload
            );
            if (!updatedData.error) {
                setIsLoading(false);
              toast.success(updatedData?.data);
              setIsClassEdited(true);
              fetchData();
              setAddNewClasses(false);
            } else {
              setIsLoading(false);
              toast.error(updatedData?.error, {
                toastId: "error",
              });
            }
          } catch (error) {
            setIsLoading(false);
            setAddNewClasses(false);
          }
        } else {
          try {
            setIsLoading(true);
            const updatedData = await createClassAPI(token, editPayload);
            if (!updatedData.error) {
                setIsLoading(false);
              toast.success(updatedData?.data);
              setIsClassEdited(true);
              setAddNewClasses(false);
              fetchData();
              const updatedFormData = [...searchedParam];
              updatedFormData[0].selectUser = "empty";
              updatedFormData[0].name = "";
              setSearchedParam(updatedFormData);

              // fetchData();
            } else {
              setIsLoading(false);
              // setAddNewClasses(false);
              toast.error(updatedData?.error, {
                toastId: "error",
              });
            }
          } catch (error) {
            setIsLoading(false);
            setAddNewClasses(false);
          }
        }
      }
    }
  };

  const classOptions = (categoryApiData || [])
    .filter((item) => item && item?.name)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const updateClassSchedule = () => {
    const updatedSchedule = selected.map((teacher) => {
      const teacherSchedule = classScheduleDetailsData.find(
        (schedule) => schedule.fk_teacher_class_schedule === teacher.value
      );
      if (teacherSchedule) {
        return {
          ...teacherSchedule,
          start_time: teacher.startTime,
          end_time: teacher.endTime,
          weekday: teacher?.weekday,
        };
      }
      return {
        fk_teacher_class_schedule: teacher.value,
        start_time: teacher.startTime,
        end_time: teacher.endTime,
        weekday: teacher?.weekday,
      };
    });
    setClassScheduleDetailsData(updatedSchedule);
  };

  useEffect(() => {
    updateClassSchedule();
  }, [selected]);

  const handleAddRows = () => {
    const newRows = {
      teacher: "",
      weekday: "",
      startTime: "",
      endTime: "",
      id: "",
    };
    setAddRows([...addRows, newRows]);
  };
  // Delete Teacher rows
  const handleDeleteRow = (index) => {
    const updatedRows = addRows.filter((_, i) => i !== index);
    setAddRows(updatedRows);
  };

  const handleFormDataChange = (index, field, event) => {
    const { value } = event.target;
    const updatedAddRows = addRows.map((row, rowIndex) => {
      if (rowIndex === index) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setAddRows(updatedAddRows);
  };

  // Category Lists
  useEffect(() => {
    const getAllCategoryData = async () => {
      const sortKey = {
        isAsc: true,
        sortId: "1",
      };
      // token, formData, page, sortKey, pageLimit
      const token = localStorage.getItem("access_item");
      const response = await getPrograms(token, formData, 0, sortKey, "300000");
      setGetCategoryApiData(response?.data);
    };
    getAllCategoryData();
  }, [data?.id]);

  // Class Type and Schedule changes
  const handleClassTypeChange = (event) => {
    setClassType(event.target.value);
  };

  const handleIsCampChange = (event) => {
    setIsNormalClass(event.target.value);
  };

  const ScheduleTable = (isNormalClass) => {
    return (
      <div className="flexbox">
        <table>
          <thead>
            <tr style={{ color: "white" }}>
              <th style={{ width: "10%", minWidth: "200px" }}>
                Select teacher
              </th>
              <th style={{ width: "10%", minWidth: "200px" }}>Weekday</th>
              <th style={{ width: "10%", minWidth: "200px" }}>Start time</th>
              <th style={{ width: "10%", minWidth: "200px" }}>End time</th>
              <th style={{ width: "2%", minWidth: "200px" }}></th>
            </tr>
          </thead>
          <tbody>
            {addRows?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>
                    <select
                      name=""
                      className="adminform__select"
                      style={{ minWidth: "100%" }}
                      onChange={(event) =>
                        handleFormDataChange(index, "teacher", event)
                      }
                      value={row.teacher}
                    >
                      <option value="" selected disabled>
                        Select teacher
                      </option>
                      {getTeacherData?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name=""
                      className="adminform__select"
                      style={{ minWidth: "100%" }}
                      onChange={(event) =>
                        handleFormDataChange(index, "weekday", event)
                      }
                      value={row.weekday}
                    >
                      <option value="" selected disabled>
                        Select Weekday
                      </option>
                      {days?.map((item, index) => (
                        <option value={item?.label} key={index}>
                          {item?.value}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="time"
                      placeholder="hh:mm"
                      className="adminform__input"
                      onChange={(event) =>
                        handleFormDataChange(index, "startTime", event)
                      }
                      style={{ minWidth: "60px" }}
                      value={row.startTime}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      placeholder="hh:mm"
                      className="adminform__input"
                      onChange={(event) =>
                        handleFormDataChange(index, "endTime", event)
                      }
                      style={{ minWidth: "60px" }}
                      value={row.endTime}
                    />
                  </td>
                  {/* MAX STREBTH  */}

                  <td>
                    {" "}
                    <button
                      type="button"
                      onClick={() => handleDeleteRow(index)}
                      style={{ marginLeft: "97%" }}
                    >
                      <DeleteCardIcon />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  // PREFILLED
  useEffect(() => {
    const setPrefilledData = async () => {
      const formattedStartDate = data?.start_date
        ? formatDateWithoutNewPrefilled(data.start_date)
        : null;
      const formattedEndDate = data?.end_date
        ? formatDateWithoutNewPrefilled(data.end_date)
        : null;
      if (data && data?.id) {
        if (data?.is_combo) {
          setIsNormalClass("CAMP");
        } else {
          setIsNormalClass("NORMAL");
        }
        setclassFormData((prevData) => ({
          ...prevData,
          season: data?.season_name,
          gender: data?.gender,
          weekday: "",
          className: data?.name,
          totalTuition: "",
          websiteGroup: data?.website,
          minAge: data?.min_age,
          maxAge: data?.max_age,
          period: "Monthly",
          max_strength: data?.max_strength,
          tuition: data?.tuition.toString(),
          hidden: false,
          teachers: [],
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        }));
      }
      if (data?.categoryId) {
        const selectedCategory = classOptions.find(
          (option) => option.value === data.categoryId
        );
        setSelectedCategory(selectedCategory);
      }
    };

    setPrefilledData();
  }, [data, categoryApiData]);

  return (
    <>
      {isLoading && <LoaderCompnent />}
      <div
        className={`Admin-modal ${openAddNewClass ? "Admin-modal--open" : ""}`}
        id="modalAddNewClass"
        role="dialog"
      >
        <div
          class="Admin-modal__dialog Admin-modal__dialog--xl"
          style={{ width: "max-content" }}
        >
          <div class="Admin-modal__content" style={{ margin: "0px 10px" }}>
            <div class="Admin-modal__head" style={{ borderRadius: "10px" }}>
              <h2 class="Admin-modal__head--title">
                + {type === "Add" ? "Add New Class" : "Edit Class"}
              </h2>
              <button
                class="Admin-modal__close"
                onClick={() => {
                  setAddNewClasses(false);
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <div class="Admin-modal__body">
              {/* CLASS TYPE  */}
              <div className="class_name">
                <div>
                  <label htmlFor="">CLASS TYPE : </label>
                </div>
                {(data?.period !== "Weekly" || type === "Add") && (
                  <div className="class_name">
                    <input
                      type="radio"
                      id="MONTHLY"
                      name="classType"
                      value="MONTHLY"
                      className=""
                      checked={classType === "MONTHLY"}
                      onChange={handleClassTypeChange}
                      defaultChecked
                    />
                    <label htmlFor="MONTHLY">Monthly </label>
                  </div>
                )}
                {(data?.period === "Weekly" || type === "Add") && (
                  <div className="class_name">
                    <input
                      className=""
                      type="radio"
                      id="WEEKLY"
                      name="classType"
                      value="WEEKLY"
                      checked={classType === "WEEKLY"}
                      onChange={handleClassTypeChange}
                    />
                    <label htmlFor="WEEKLY">Weekly </label>
                  </div>
                )}
              </div>

              {/* FORMS DATA MAIN  */}

              <div class="flexbox">
                <div class="column">
                  <div class="adminform__group">
                    <label htmlFor="" className="label">
                      Choose Season
                    </label>
                    <input
                      type="text"
                      className="adminform__input"
                      placeholder="Season name"
                      name="season"
                      onChange={handleInputChange}
                      value={classFormData?.season}
                    // value={editData?.season || ""}
                    />
                  </div>
                </div>
                <div class="column">
                  <div class="adminform__group">
                    <label htmlFor="" className="label">
                      Gender
                    </label>
                    <select
                      className="adminform__select"
                      name="gender"
                      value={classFormData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Coed">Coed</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* START DATE OR END DATE  */}
              {classType === "MONTHLY" && (
                <div class="flexbox">
                  <div class="column">
                    <div class="adminform__group">
                      <label htmlFor="start_date" className="label">
                        Start Date
                      </label>
                      <DatePicker
                        placeholderText="MM/DD/YYYY"
                        className="adminform__input"
                        selected={classFormData?.start_date}
                        onChange={(date) =>
                          handleDateChange(date, "start_date")
                        }
                      />
                    </div>
                  </div>
                  <div class="column">
                    <div class="adminform__group">
                      <label htmlFor="" className="label">
                        End Date
                      </label>

                      <DatePicker
                        placeholderText="MM/DD/YYYY"
                        className="adminform__input"
                        selected={classFormData?.end_date}
                        onChange={(date) => handleDateChange(date, "end_date")}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Class Name & Category  */}
              <div class="flexbox">
                <div class="column">
                  <div class="adminform__group">
                    <label htmlFor="" className="label">
                      {classType == "WEEKLY" ? "Camp Name" : "Class Name"}
                    </label>

                    <input
                      name="className"
                      type="text"
                      className="adminform__input"
                      placeholder={
                        classType == "WEEKLY" ? "Camp Name" : "Class Name"
                      }
                      value={classFormData.className}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="">
                    <label htmlFor="" className="label">
                      Select Category
                    </label>

                    <Select
                      className="multiSelectInput"
                      options={classOptions}
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      placeholder="Select category"
                      isSearchable={true}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              {classType == "WEEKLY" && (
                <div>
                  {/* Min Age & Max Age  */}
                  <div class="flexbox">
                    <div class="column">
                      <div class="adminform__group">
                        <label htmlFor="" className="label">
                          Min Age
                        </label>

                        <input
                          type="number"
                          class="adminform__input"
                          placeholder="Min Age (Months)"
                          name="minAge"
                          value={classFormData.minAge}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div class="column">
                      <div class="adminform__group">
                        <label htmlFor="maxAge" className="label">
                          Max Age
                        </label>
                        <input
                          id="maxAge"
                          type="number"
                          class="adminform__input"
                          placeholder="Max Age (Months)"
                          name="maxAge"
                          value={classFormData.maxAge}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Websitegroup and Period */}
                  <div className="flexbox">
                    <div className="column">
                      <div class="adminform__group">
                        <label htmlFor="" className="label">
                          Website Group
                        </label>
                        <select
                          class="adminform__select"
                          name="websiteGroup"
                          value={classFormData.websiteGroup}
                          onChange={handleInputChange}
                        >
                          <option value="" selected disabled>
                            Website Group
                          </option>
                          <option value="GymTime">GymTime</option>
                        </select>
                      </div>
                    </div>

                    <div className="column">
                      <div class="adminform__group">
                        <label htmlFor="period" className="label">
                          Period
                        </label>

                        <select
                          class="adminform__select"
                          name="period"
                          value={classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          onChange={handleInputChange}
                          disabled
                        >
                          <option selected disabled>
                            Period
                          </option>
                          <option
                            value={classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          >
                            {" "}
                            {classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* ++ Add SCEHDULES  */}
              <div class="flexbox">
                <div class="column">
                  <div class="adminbutton__group" style={{ width: "100%" }}>
                    {classType == "MONTHLY" ? (
                      <div className="flexbox">
                        {/* ADD SCHEDULES BUTTON */}
                        <div className="column">
                          {isNormalClass === "NORMAL" ? (
                            <button
                              className="adminbtn adminbtn-blueOutline"
                              onClick={handleAddRows}
                              style={{ minWidth: "160px" }}
                            >
                              + Add Schedules
                            </button>
                          ) : (
                            <button
                              className="adminbtn adminbtn-blueOutline"
                              onClick={handleAddComboClass}
                              style={{ minWidth: "160px" }}
                            >
                              + Add Schedules
                            </button>
                          )}
                        </div>

                        {/* CAMP & Normal Clases- RADIO  */}
                        <div className="column">
                          <div className="class_name">
                            <div className="adminform__grayBox">
                              <input
                                type="radio"
                                id="normalClass"
                                name="normal_class"
                                value="NORMAL"
                                className="adminform"
                                checked={isNormalClass === "NORMAL"}
                                onChange={handleIsCampChange}
                              />
                              <label className="label" htmlFor="normalClass">
                                Normal
                              </label>
                            </div>
                            <div className="adminform__grayBox">
                              <input
                                type="radio"
                                id="campClass"
                                name="normal_class"
                                value="CAMP"
                                className="adminform__grayBox"
                                checked={isNormalClass === "CAMP"}
                                onChange={handleIsCampChange}
                              />
                              <label className="label" htmlFor="campClass">
                                Combo
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flexbox">
                        <div className="class_name">
                          <div className="class_name">
                            <input
                              type="checkbox"
                              id="halfDay"
                              // name="campDay"
                              value="halfDay"
                              className=""
                              checked={campDay?.half_day}
                              onChange={(e) => handleCampDay(e, "half_day")}
                              defaultChecked
                            />
                            <label htmlFor="halfDay" className="label">
                              Half Day
                            </label>
                          </div>
                          <div className="class_name">
                            <input
                              className=""
                              type="checkbox"
                              id="fullDay"
                              // name="campDay"
                              value="fullDay"
                              checked={campDay?.full_day}
                              onChange={(e) => handleCampDay(e, "full_day")}
                            />
                            <label htmlFor="fullDay" className="label">
                              Full Day
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* CAMP timing  */}

              {/* Logic for Table , Schedules & Camp Clas  */}
              {classType == "WEEKLY" ? (
                <CampClassTable
                  rows={campRows}
                  handleAddRow={handleAddCampRow}
                  handleDeleteRow={handleDeleteCampRow}
                  handleChange={handleChangeCamp}
                  getTeacherData={getTeacherData}
                  campDay={campDay}
                  setCampRows={setCampRows}
                  setCampTime={setCampTime}
                  campTime={campTime}
                  classType={classType}
                  setCampType={setCampType}
                  fullDayCampRow={fullDayCampRow}
                  setFullDayCampRow={setFullDayCampRow}
                  setFullDayCampTime={setFullDayCampTime}
                  fullDayCampTime={fullDayCampTime}
                  handleChangeCampFullDay={handleChangeCampFullDay}
                />
              ) : isNormalClass === "NORMAL" ? (
                ScheduleTable(isNormalClass)
              ) : (
                <ComboClassTable
                  rows={comboRows}
                  handleAddRow={handleAddComboClass}
                  handleDeleteRow={handleDeleteComboClass}
                  handleChange={handleChangeComboClass}
                  getTeacherData={getTeacherData}
                  days={days}
                />
              )}

              {/* TUITION & MAX STRENGHT  */}
              {isNormalClass === "NORMAL" && classType == "MONTHLY" ? (
                <div class="flexbox">
                  <div class="column">
                    <div class="adminform__group">
                      <label htmlFor="max_strength" className="label">
                        Max Students
                      </label>

                      <input
                        id="max_strength"
                        type="number"
                        class="adminform__input"
                        placeholder="Max Strength"
                        name="max_strength"
                        value={classFormData.max_strength}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {classType == "MONTHLY" && (
                <div>
                  <div className="flexbox">
                    <div class="column">
                      <div className="adminform__group">
                        <label htmlFor="tuition" className="label">
                          Tuition($)
                        </label>
                        <input
                          id="tuition"
                          type="number"
                          style={{ minWidth: "180px" }}
                          class="adminform__input"
                          placeholder="Tuitionddddddd ($)"
                          name="tuition"
                          value={classFormData?.tuition}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Min Age & Max Age  */}
                  <div class="flexbox">
                    <div class="column">
                      <div class="adminform__group">
                        <label htmlFor="" className="label">
                          Min Age
                        </label>

                        <input
                          type="number"
                          class="adminform__input"
                          placeholder="Min Age (Months)"
                          name="minAge"
                          value={classFormData.minAge}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div class="column">
                      <div class="adminform__group">
                        <label htmlFor="maxAge" className="label">
                          Max Age
                        </label>
                        <input
                          id="maxAge"
                          type="number"
                          class="adminform__input"
                          placeholder="Max Age (Months)"
                          name="maxAge"
                          value={classFormData.maxAge}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Websitegroup and Period */}
                  <div className="flexbox">
                    <div className="column">
                      <div class="adminform__group">
                        <label htmlFor="" className="label">
                          Website Group
                        </label>
                        <select
                          class="adminform__select"
                          name="websiteGroup"
                          value={classFormData.websiteGroup}
                          onChange={handleInputChange}
                        >
                          <option value="" selected disabled>
                            Website Group
                          </option>
                          <option value="GymTime">GymTime</option>
                        </select>
                      </div>
                    </div>

                    <div className="column">
                      <div class="adminform__group">
                        <label htmlFor="period" className="label">
                          Period
                        </label>

                        <select
                          class="adminform__select"
                          name="period"
                          value={classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          onChange={handleInputChange}
                          disabled
                        >
                          <option selected disabled>
                            Period
                          </option>
                          <option
                            value={classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          >
                            {" "}
                            {classType == "WEEKLY" ? "Weekly" : "Monthly"}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Footer  */}
            <div class="Admin-modal__footer">
              <div
                class="admin-button__group"
                style={{
                  display: "flex",
                  alignItems: "right",
                  marginLeft: "75%",
                  gap: "20px",
                }}
              >
                <button
                  class="adminbtn adminbtn-blueOutline"
                  onClick={() => {
                    setAddNewClasses(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  class="adminbtn adminbtn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewClass;
